import * as THREE from 'three';
import {DynamicLine} from "@/render_utils/three_tools/creation_tools/DynamicLine";
import {NameChildGiz, NameGiz} from "@/render_utils/three_tools/gizmos_tools/giz_tools";

export function createSpotGiz(spotLight) {
    const radius = 0.05;
    // const frustum = lightShineIcon(radius*6);
    const frustum = lightShineIcon();
    const sphereGeometry = new THREE.SphereGeometry(radius, 32, 32);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
    const icon = new THREE.Mesh(sphereGeometry, sphereMaterial);

    const target = spotLight.target;
    const hit = new THREE.Group();
    spotLight.add(hit);
    hit.name = "REAL_EXTRA";
    const line = new DynamicLine(hit, target, 0xffffff);
    icon.depends = [line, target, hit];
    icon.add(line);

    const gizGroup = new THREE.Group();
    gizGroup.add(frustum);
    icon.add(gizGroup);

    spotLight.name = "REAL_SPOT_LIGHT"
    icon.add(spotLight);

    NameGiz(frustum);
    NameGiz(gizGroup);
    NameChildGiz(icon);

    icon.userData.canLight = true;
    icon.userData.ignoreScale = true;
    icon.userData.childID = spotLight.id;
    icon.userData.type = "REAL_SPOT_LIGHT";
    return icon;
}


function lightShineIcon() {
    const sw = 0.25;
    const sh = 0.25;
    const sl = 0.25;

    const color = new THREE.Color('white');
    const material = new THREE.LineBasicMaterial({ color: color });


    const points = [
        new THREE.Vector3( sw/2, sh/2, sl/2 ),
        new THREE.Vector3( -sw/2, -sh/2, -sl/2 ),
        new THREE.Vector3( -sw/2, sh/2, sl/2 ),
        new THREE.Vector3( sw/2, -sh/2, -sl/2 ),
        new THREE.Vector3( -sw/2, -sh/2, sl/2 ),
        new THREE.Vector3( sw/2, sh/2, -sl/2 ),
        new THREE.Vector3( sw/2, -sh/2, sl/2 ),
        new THREE.Vector3( -sw/2, sh/2, -sl/2 ),
        new THREE.Vector3( sw/2, sh/2, sl/2 ),
    ];

    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    return new THREE.LineSegments(geometry, material);
}
