<template>
  <OptionForm @submit="addSunLight" @close="$emit('closeMenus')">
    <ColorForm label="Color" :def="color" @onChange="setColor" />
    <InputForm label="Intensity" type="number" :def="intensity" @onChange="setIntensity" />
    <CheckboxForm label="Cast Shadow" :def="castShadow" @onChange="setCastShadow" />
<!--    <CheckboxForm label="Make target as direction (not recommended)" :def="target" @onChange="setAsTarget" />-->
  </OptionForm>
</template>

<script>
import * as REAL from "real_api";
import ColorForm from "@/components/form/option/ColorForm.vue";
import InputForm from "@/components/form/option/InputForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";
import {convertHTMLColorToThreeColor} from "@/render_utils/three_tools/creation_tools/color_tools";

export default {
  name: 'AddSunLight',
  emits: ['closeMenus', 'addSceneItem'],
  components: {InputForm, CheckboxForm, OptionForm, ColorForm},
  data() {
    return {
      color: '#FFFFFF',
      intensity: 1,
      target: false,
      castShadow: true,
    }
  },
  methods: {
    addSunLight() {
      this.$emit("closeMenus");
      const color  = convertHTMLColorToThreeColor(this.color);
      const sun = new REAL.SunLight({
        color: color,
        target: this.target,
        intensity: this.intensity,
        castShadow: this.castShadow,
      });
      this.$emit("addSceneItem", sun, "sun_light");
    },
    setAsTarget(value){this.target = value},
    setCastShadow(value){this.castShadow = value},
    setIntensity(value){this.intensity = value},
    setColor(value){this.color = value},
  }
};
</script>
