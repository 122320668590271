import * as THREE from 'three';
import {NameGiz, NameCameraGiz} from "@/render_utils/three_tools/gizmos_tools/giz_tools";

export function createCameraGiz(camera) {
    const rectWide = 1;
    const rectHigh = 0.5;
    const triWide = 0.5;
    const rectLength = 2;
    const spRadius = 0.05;

    const color = new THREE.Color('white'); // TODO: Color mode dark/light
    const material = new THREE.LineBasicMaterial({ color: color });


    const points = [
        new THREE.Vector3( 0, 0, 0 ),
        new THREE.Vector3( -rectWide, -rectHigh, -rectLength ),
        new THREE.Vector3( 0, 0, 0 ),
        new THREE.Vector3( rectWide, -rectHigh, -rectLength ),
        new THREE.Vector3( 0, 0, 0 ),
        new THREE.Vector3( rectWide, rectHigh, -rectLength ),
        new THREE.Vector3( 0, 0, 0 ),
        new THREE.Vector3( -rectWide, rectHigh, -rectLength ),
        new THREE.Vector3( 0, 0, 0 ),
    ];

    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const frustum = new THREE.LineSegments(geometry, material);

    // 2. Create Camera icon (a small sphere)
    const sphereGeometry = new THREE.SphereGeometry(spRadius, 32, 32);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const icon = new THREE.Mesh(sphereGeometry, sphereMaterial);

    const rectangleGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(-rectWide, -rectHigh, -rectLength),
        new THREE.Vector3(rectWide, -rectHigh, -rectLength),
        new THREE.Vector3(rectWide, rectHigh, -rectLength),
        new THREE.Vector3(-rectWide, rectHigh, -rectLength),
        new THREE.Vector3(-rectWide, -rectHigh, -rectLength),
    ]);
    const rectangleMaterial = new THREE.LineBasicMaterial({ color: color });
    const cameraOutline = new THREE.Line(rectangleGeometry, rectangleMaterial);

    const triangleGeometry = new THREE.BufferGeometry();
    const vertices = new Float32Array([
        0, triWide, 0,
        -triWide, 0, 0,
        triWide, 0, 0
    ]);
    triangleGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const triangleMaterial = new THREE.MeshBasicMaterial({color: color, side: THREE.DoubleSide});
    const triangle = new THREE.Mesh(triangleGeometry, triangleMaterial);
    triangle.position.set(0, rectHigh, -rectLength);

    const gizGroup = new THREE.Group();
    gizGroup.add(triangle);
    gizGroup.add(frustum);
    gizGroup.add(cameraOutline);

    icon.add(gizGroup);

    // camera.add(icon);
    icon.add(camera);

    NameGiz(frustum);
    NameGiz(gizGroup);
    NameGiz(triangle);
    NameCameraGiz(icon);
    NameGiz(cameraOutline);

    icon.userData.canClone = true;
    icon.userData.ignoreScale = true;
    icon.userData.childID = camera.id;
    icon.userData.type = "REAL_CAM_GIZ";
    return icon;
}
