import * as THREE from 'three';

export class DynamicLine extends THREE.Line {
    constructor(objectA, objectB, color = 0xffff00) {
        const points = [];
        points.push(objectA.position);
        points.push(objectB.position);
        const geometry = new THREE.BufferGeometry().setFromPoints( points );
        geometry.dynamic = true;
        geometry.verticesNeedUpdate = true;
        const material = new THREE.LineBasicMaterial({ color: color });
        super(geometry, material);

        this.name = "REAL_LINE_GIZ";
        this.objectA = objectA;
        this.objectB = objectB;

        this.oldPositionA = objectA.position.clone();
        this.oldPositionB = objectB.position.clone();

        this.updateInterval = setInterval(() => this.updateIfNeeded(), 100);  // check every 100ms
    }

    updateIfNeeded() {
        if(!this.changed()) return;
        this.updateLine();
        this.oldPositionA.copy(this.objectA.position);
        this.oldPositionB.copy(this.objectB.position);
    }

    changed() {
        return !this.objectA.position.equals(this.oldPositionA) || !this.objectB.position.equals(this.oldPositionB);
    }

    updateLine() {
        this.geometry.setFromPoints([this.objectA.position, this.objectB.position]);
        this.geometry.verticesNeedUpdate = true;
    }

    dispose() {
        // Be sure to clear the interval when you're done with the object.
        clearInterval(this.updateInterval);
    }
}
