import '@/style.css';
import App from './App.vue'
import {createApp} from 'vue'
import {LoadConfig} from "@/utils/Config";
import CacheManager from "@/utils/CacheManager";
import {RequestManager} from "@/utils/RequestManager";
import CryptoManager from "@/utils/CryptoManager";


const setupApp = async () => {
    const cache = new CacheManager();
    const config = await LoadConfig();
    const crypto = new CryptoManager();
    const request = new RequestManager(config);
    const params = new URLSearchParams(window.location.search);

    const app = createApp(App);
    app.provide('cache', cache);
    app.provide('query', params);
    app.provide('crypto', crypto);
    app.provide('config', config);
    app.provide('request', request);
    app.mount('#app');
}

setupApp().then(r => {return r});
