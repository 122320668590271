import * as THREE from 'three';
import {NameChildGiz, NameGiz} from "@/render_utils/three_tools/gizmos_tools/giz_tools";

export function createPointGiz(pointLight) {
    const radius = 0.05;
    const frustum = lightShineIcon(radius*3);
    const sphereGeometry = new THREE.SphereGeometry(radius, 32, 32);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x1ca9e6 });
    const icon = new THREE.Mesh(sphereGeometry, sphereMaterial);

    const gizGroup = new THREE.Group();
    gizGroup.add(frustum);
    icon.add(gizGroup);

    pointLight.name = "REAL_POINT_LIGHT"
    icon.add(pointLight);

    NameGiz(frustum);
    NameGiz(gizGroup);
    NameChildGiz(icon);

    icon.userData.canLight = true;
    icon.userData.ignoreScale = true;
    icon.userData.ignoreRotate = true;
    icon.userData.childID = pointLight.id;
    icon.userData.type = "REAL_POINT_LIGHT";
    return icon;
}

function lightShineIcon(radius) {
    const foggyGeometry = new THREE.SphereGeometry(radius * 1.2, 32, 32);

    // Create a custom shader material for the foggy effect
    const foggyMaterial = new THREE.ShaderMaterial({
        uniforms: {
            color: { value: new THREE.Color(0x1ca9e6) },
        },
        vertexShader: `
            varying vec3 vNormal;
            void main() {
                vNormal = normal;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            varying vec3 vNormal;
            uniform vec3 color;
            void main() {
                float fogIntensity = abs(dot(normalize(vNormal), vec3(0.0, 0.0, 1.0)));
                float fogAmount = smoothstep(0.0, 0.1, fogIntensity); // Adjust the fog intensity
                vec3 finalColor = mix(color, vec3(1.0), fogAmount);
                gl_FragColor = vec4(finalColor, 0.5); // Adjust the alpha value to control transparency
            }
        `,
        transparent: true,
        blending: THREE.AdditiveBlending,
        depthWrite: false,
    });
    return new THREE.Mesh(foggyGeometry, foggyMaterial);
}
