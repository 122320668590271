<template>
  <div @mouseover="showButtons = true" @mouseleave="showButtons = false">
    <input type="file" @change="loadModel" ref="fileInput" class="hidden" />
    <input type="file" @change="openScene" ref="sceneFileInput" class="hidden" accept=".r3d" />

    <MenuButton
        title="Options"
        :img="icons.addIcon"
        :ignoreHV="true"
    />

    <div class="absolute top-full left-0 w-56 py-2 bg-sc-dark2 rounded-md shadow-lg" v-if="showButtons">
      <DropButton
          :group="true"
          title="Import Model"
          :img="icons.impIcon"
      >
        <DropButton
            title="gltf/glb"
            :img="icons.impIcon"
            @click="triggerFileInput(['gltf', 'glb'])"
        />
        <DropButton
            title="fbx"
            :img="icons.impIcon"
            @click="triggerFileInput(['fbx'])"
        />
        <DropButton
            title="stl"
            :img="icons.impIcon"
            @click="triggerFileInput(['stl'])"
        />
      </DropButton>

      <DropButton
          title="Open"
          :img="icons.openIcon"
          @click="openSceneFile"
      />
      <DropButton
          title="Save"
          :img="icons.saveIcon"
          @click="saveScene"
      />

      <DropButton
          title="Camera"
          :img="icons.camIcon"
          @click="addClickToggle('camera', true)"
      />

      <DropButton
          :group="true"
          title="Light"
          :img="icons.impIcon"
      >
        <DropButton
            title="Sun light"
            :img="icons.sunIcon"
            @click="addClickToggle('sunLight', true)"
        />
        <DropButton
            title="Area light"
            :img="icons.lampIcon"
            @click="addClickToggle('areaLight', true)"
        />
        <DropButton
            title="Spotlight"
            :img="icons.spotIcon"
            @click="addClickToggle('spotLight', true)"
        />
        <DropButton
            title="Point light"
            :img="icons.pointIcon"
            @click="addClickToggle('pointLight', true)"
        />
      </DropButton>

      <DropButton
          title="Export Model"
          :img="icons.expIcon"
          @click="$emit('exportScene')"
      />
    </div>
  </div>

</template>

<script>
import {IconsUI} from "@/utils/IconsUI";
import MenuButton from "@/components/ui/MenuButton.vue";
import {GetFileInfo, importModelFile, ValidExt} from "@/render_utils/three_tools/import_tools/model_importer";
import DropButton from "@/components/ui/DropButton.vue";
import EventBus from "@/utils/EventBus";

export default {
  name: "FileMenu",
  emits: ['saveScene', 'addClickToggle', 'exportScene'],
  components: {DropButton, MenuButton},
  props: {
    icons: {type: IconsUI, required: true},
  },
  data () {
    return {
      showButtons: false,
      fileError: undefined,
    }
  },
  methods: {
    async loadModel(event) {
      if(!event.target.files) return EventBus.emit("setError", "No file selected!");
      const validExt = ValidExt;
      const localFile = event.target.files[0];
      const fileInfo = GetFileInfo(localFile);
      if(!validExt.includes(fileInfo.ext)) return EventBus.emit('setError', 'Unknown file type!');
      await importModelFile(localFile, fileInfo);
    },
    async openSceneFile() {
      this.$refs.sceneFileInput.click();
    },
    async openScene(event) {
      if(!event.target.files) return EventBus.emit("setError", "No file selected!");
      const localFile = event.target.files[0];
      const fileInfo = GetFileInfo(localFile);
      if(!['r3d'].includes(fileInfo.ext)) return EventBus.emit('setError', 'Unknown file type!');
      const reader = new FileReader();
      reader.onload = function(event) {
        const fileData = event.target.result;
        EventBus.emit("openScene", fileData);
      };
      reader.readAsArrayBuffer(localFile);
    },
    saveScene(){
      EventBus.emit("saveScene");
    },
    triggerFileInput(extList) {
      this.$refs.fileInput.accept = this.acceptExt(extList);
      this.$refs.fileInput.click();
    },
    acceptExt(extList) {
      return extList.map(ext => `.${ext}`).join(",");
    },
    addClickToggle(key, value) {
      this.$emit('addClickToggle', key, value);
    },
  },
}
</script>

