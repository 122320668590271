<template>
  <div class="flex items-center" @mouseover="showButtons = true" @mouseleave="showButtons = false" >
    <button @click="onClick" class="flex w-56 items-center px-4 py-2 hover:bg-sc-dark2-l">
    <img
        :src="img"
        :alt="title"
        class="size-4 mr-2"
    />
    <span class="text-white">{{ title }}</span>
    </button>
    <img v-if="group" :src="downArrow" class="fill-current size-4 -rotate-90" style="filter: invert(100%); fill: white;" alt="arrow" >
    <div
        class="absolute mt-0 ml-56 w-56 py-2 bg-sc-dark2 rounded-md shadow-lg"
        :style="{ top: getRect() }"
        v-if="group && showButtons">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import downArrow from "@/assets/img/d-arrow.svg";

export default {
  name: "DropButton",
  emits: ['click'],
  data () {
    return {
      showButtons: false,
      downArrow: downArrow
    }
  },
  props: {
    img: {type: String, required: true},
    title: {type: String, required: true},
    group: {type: Boolean, required: false, default: () => {return false}},
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
    getRect() {
      const rect = this.$el.getBoundingClientRect();
      const pos = rect.top - 52;
      return `${pos}px`;
    },
  },
}
</script>
