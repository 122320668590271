import EventBus from "@/utils/EventBus";
import StlImporter from "@/render_utils/three_tools/import_tools/stl_importer";
import FbxImporter from "@/render_utils/three_tools/import_tools/fbx_importer";
import GltfImporter from "@/render_utils/three_tools/import_tools/gltf_importer";

export const ValidExt = [
    "glb", "gltf", "fbx", "stl", // "blend",
]

export function GetFileInfo(file) {
    const fileSize = Math.round(file.size / (1024*1024));
    const fileName = file.name;
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    return {
        name: fileName,
        size: fileSize,
        ext: String(fileExtension).toLowerCase()
    }
}

const importLogics = {
    gltf: GltfImporter,
    glb: GltfImporter,
    fbx: FbxImporter,
    stl: StlImporter,
}

export async function importModelFile(file, fileInfo) {
    if (!file || !fileInfo) return;
    const ext = fileInfo.ext;
    if(!importLogics[ext]) return EventBus.emit('setError', 'Unsupported file format');
    const logic = new importLogics[ext]();
    await logic.import(file);
}
