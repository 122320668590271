<template>
  <OptionForm @submit="addAreaLight" @close="$emit('closeMenus')">
    <InputForm label="Width" type="number" :def="width" @onChange="setWidth" />
    <InputForm label="Height" type="number" :def="height" @onChange="setHeight" />
    <ColorForm label="Color" :def="color" @onChange="setColor" />
    <InputForm label="Intensity" type="number" :def="intensity" @onChange="setIntensity" />
    <InputForm label="Beam Angle" type="number" :def="beamAngle" @onChange="setBeam" />
    <CheckboxForm label="Multiple Importance" :def="multipleImportance" @onChange="setImportance" />
  </OptionForm>
</template>

<script>
import * as REAL from "real_api";
import InputForm from "@/components/form/option/InputForm.vue";
import ColorForm from "@/components/form/option/ColorForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";
import {convertHTMLColorToThreeColor} from "@/render_utils/three_tools/creation_tools/color_tools";

export default {
  name: 'AddAreaLight',
  emits: ['closeMenus', 'addSceneItem'],
  components: {InputForm, CheckboxForm, OptionForm, ColorForm},
  data() {
    return {
      width: 1,
      height: 1,
      color: "#FFFFFF",
      intensity: 1,
      beamAngle: 180,
      multipleImportance: true
    }
  },
  methods: {
    addAreaLight() {
      this.$emit("closeMenus");
      const color  = convertHTMLColorToThreeColor(this.color);
      const light = new REAL.AreaLight(this.width, this.height, color, this.intensity);
      light.width = this.width;
      light.height = this.height;
      light.beamAngle = this.beamAngle;
      light.castShadow = this.castShadow;
      light.multipleImportance = this.multipleImportance;
      light.rotation.y = Math.PI;
      this.$emit("addSceneItem", light, "area_light")
    },
    setColor(value){this.color = value},
    setWidth(value){this.width = value},
    setHeight(value){this.height = value},
    setBeam(value){this.beamAngle = value},
    setIntensity(value){this.intensity = value},
    setImportance(value){this.multipleImportance = value},
  }
};
</script>
