<template>
  <div class="flex items-start mb-5">
    <div class="flex items-center h-5">
      <input id="terms" type="checkbox" v-model="onChange" :required="require" class="size-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" />
    </div>
    <label for="terms" class="ms-2 text-sm font-medium text-gray-300">{{label}}</label>
  </div>
</template>
<script>
export default {
  name: "CheckboxForm",
  emits: ['onChange'],
  props: {
    label: {type: String, required: true},
    def: {type: Boolean, required: false, default: ()=>{return false}},
    require: {type: Boolean, required: false, default: ()=>{return false}},
  },
  data () {
    return{
      onChange: this.def
  }},
  watch: {
    onChange(value){
      this.$emit('onChange', value);
    },
  }
}
</script>
