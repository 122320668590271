<template>
  <OptionForm @submit="addCamera" @close="$emit('closeMenus')">
    <DropForm  label="Type" :options="['Perspective', 'Orthographic']" @onChange="setCameraType" />
    <CheckboxForm label="Set as default" :def="isDefault" @onChange="setCameraDef" />
    <InputForm label="Near" type="number" :def="near" @onChange="setNear" />
    <InputForm label="Far" type="number" :def="far" @onChange="setFar" />
    <InputForm label="FOV" type="number" :def="fov" @onChange="setFov" />
  </OptionForm>
</template>

<script>
import { PerspectiveCamera, OrthographicCamera } from "three";
import OptionForm from "@/components/form/option/OptionForm.vue";
import DropForm from "@/components/form/option/DropForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";
import InputForm from "@/components/form/option/InputForm.vue";

export default {
  name: 'AddCamera',
  components: {InputForm, CheckboxForm, DropForm, OptionForm},
  emits: ['closeMenus', 'addSceneItem'],
  data() {
    return {
      cameraType: "perspective",
      isDefault: true,
      near: 0.1,
      far: 100,
      fov: 75,
      aspect: window.innerWidth / window.innerHeight
    }
  },
  methods: {
    addCamera() {
      let camera;
      if (this.cameraType === "perspective") {
        camera = new PerspectiveCamera(this.fov, this.aspect, this.near, this.far);
      } else if (this.cameraType === "orthographic") {
        let frustumSize = 2;
        let aspect = this.aspect;
        camera = new OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, this.near, this.far );
      }
      this.$emit("addSceneItem", camera, "camera", this.isDefault);
      this.$emit("closeMenus");
    },
    setFar(value) {
      this.far = value;
    },
    setFov(value) {
      this.fov = value;
    },
    setNear(value) {
      this.near = value;
    },
    setCameraDef(value) {
      this.isDefault = value;
    },
    setCameraType(value){
      this.cameraType = String(value).toLowerCase();
    },
  }
};
</script>
