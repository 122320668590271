import EventBus from "@/utils/EventBus";

export async function RenderJob(realScene, app) {
    const cred = app.cred;
    if(!realScene || !cred) return;
    app.showProgress(true);
    try {
        const request = app.request;
        const renderParams = {
            ext: "r3d",
            bake: false,
            output: "PNG",
            expFrom: "app",
        };
        let response = await request.reqNewJob(cred, renderParams);
        app.setProgress(60);
        if(response.msg !== "SUCCESS") return ErrorReject("ERROR: Creating URL");

        const data = response.data;
        const uploadURL = data.url;
        response = await request.uploadJob(uploadURL, realScene);
        app.setProgress(90);
        if(response !== "SUCCESS") return ErrorReject(`ERROR: ${response}`);

        response = await request.reqSubmitJob(cred, data.jobID);
        app.setProgress(99);
        if(response.msg !== "SUCCESS") return ErrorReject(`ERROR submit: ${response}`);
        EventBus.emit('viewJobs', true);
    }
    catch (e) {
        ErrorReject(e);
    }
    app.showProgress(false);
}

export function ErrorReject(error) {
    EventBus.emit("setError", error);
}
