import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";

export default class GltfExporter {
    constructor() {
    }
    async export(scene, options) {
        // options = { binary: false };
        const isBin = options.binary;
        const exporter = new GLTFExporter();
        const buffer = await new Promise((resolve, reject) => {
            exporter.parse(scene,
                (glb) => resolve(glb),
                (err) => reject(err),
                options);
        });
        if(isBin) return new Blob([buffer], { type: 'application/octet-stream' });
        return new Blob([JSON.stringify(buffer)], { type: 'application/octet-stream' });
    }
}
