import * as THREE from "three";
import {NameEmptyGiz} from "@/render_utils/three_tools/gizmos_tools/giz_tools";


/**
 * Creates an invisible clickable box around a given object.
 *
 * @param {THREE.Object3D} object - The object around which the box will be created.
 * @return {THREE.Mesh} - The invisible clickable box.
 */
export function createBoundingBox(object) {
    // 1. Calculate the bounding box of the model
    const boundingBox = new THREE.Box3().setFromObject(object);
    const center = new THREE.Vector3();
    const size = new THREE.Vector3();
    boundingBox.getCenter(center);
    boundingBox.getSize(size);

    // 2. Create the box geometry using the calculated size
    const boxGeometry = new THREE.BoxGeometry(size.x, size.y, size.z);

    // Invisible material (but can still be interacted with)
    const boxMaterial = new THREE.MeshBasicMaterial({
        visible: false
    });

    // Create the invisible box mesh
    const box = new THREE.Mesh(boxGeometry, boxMaterial);

    NameEmptyGiz(box);
    // 3. Adjust the object's position so it's centered inside the box
    object.position.sub(center);

    // 4. Add the model as a child to the box
    box.add(object);

    return box;
}
