
export function NameGiz(item) {
    item.name = "REAL_GIZ";
}

export function NameChildGiz(item) {
    item.name = "REAL_CHILD_GIZ";
}

export function NameCameraGiz(item) {
    item.name = "REAL_CAM_GIZ";
}

export function NameParentGiz(item) {
    item.name = "REAL_PARENT_GIZ";
}

export function NameEmptyGiz(item) {
    item.name = "REAL_Empty_GIZ";
}

export function NameExtra(item) {
    item.name = "REAL_EXTRA";
}

