<template>
  <div class="app">
    <ErrorView :errorMessage="error" @clearError="clearError" />
    <ErrorOnlineView :errorMessage="onlineError" @onlineClick="reLogin" @clearError="clearOnlineError" />
    <RenderPage
        ref="renderPage"
        :cred="cred"
        :cache="cache"
        :socket="socket"
        :encKey="encKey"
        :crypto="crypto"
        :request="request"
        :userData="userData"
    />
  </div>
</template>
<script>

import EventBus from "@/utils/EventBus";
import RenderPage from "@/pages/RenderPage.vue";
import ErrorView from "@/components/ui/ErrorView.vue";
import {LoginUser, userLoggedIn} from "@/tools/login_tools";
import SocketManager from "@/utils/SocketManager";
import ErrorOnlineView from "@/components/ui/ErrorOnlineView.vue";

export default {
  name: 'App',
  inject: ['query', 'cache', 'config', 'request', 'crypto'],
  components: {
    ErrorOnlineView,
    ErrorView,
    RenderPage,
  },
  data() {
    return {
      error: null,
      onlineError: null,
      cred: {},
      userData: {},
      encKey: "f63c112085c408391ade434b4d1243b2",
      socket: new SocketManager(this.onSocketMessage.bind(this)),
    };
  },
  async mounted() {
    await this.start();
  },
  methods: {
    async start() {
      await this.autoLogin();
    },
    async autoLogin() {
      let data = this.query.get('data');
      let token = this.query.get('token');
      if(data && token) await LoginUser(this, data, token);
      if(userLoggedIn(this.userData)) return;
      const loginCache = this.cache.loginCache();
      data = loginCache.data;
      token = loginCache.token;
      if(data && token) await LoginUser(this, data, token);
    },
    async reLogin() {
      await this.socket.login(this.cred);
    },
    async setCred() {
      const userData = this.userData;
      if(!userData) return;
      const user = userData.user;
      const products = userData.products;
      const product = products.find(p => p.prodName === "PUBLIC");
      this.cred = {
        insID: 0,
        appKey: user.appKey,
        prodKey: product.prodKey,
      };
      await this.socket.login(this.cred);
    },
    async onSocketMessage(type, msg, data) {
      // console.log(type, msg, data);
      switch (type) {
        case "auth_success":
          this.socket.isLoggedIn = true;
          break;
        case "auth_failed":
          this.socket.offline("Login failed!");
          break;
        case "al_login":
          this.socket.offline(
              "You are logged in somewhere else. " +
              "Only one instance is allowed to use online version. " +
              "You can still continue 3D related functions."
          );
          break;
        case "job":
          this.emitJob(data);
          break;
        case "jobs":
          this.emitJobs(data);
          break;
      }
    },
    emitJob(job) {
      EventBus.emit('updateJob', job);
    },
    emitJobs(jobs) {
      EventBus.emit('updateJobs', jobs);
    },
    logoutUser(){
      // console.log("logoutUser")
      this.cache.remove('login');
      this.userData = {};
    },
    async loginClicked(loginInfo) {
      let data = loginInfo.data;
      let password = loginInfo.password;
      if(!data || !password) return;
      await LoginUser(this, data, password, true);
    },
    setError(msg) {
      this.error = msg;
    },
    setOnlineError(msg) {
      this.onlineError = msg;
    },
    clearError() {
      this.error = null;
    },
    clearOnlineError() {
      this.onlineError = null;
    },
  },
  beforeMount() {
    EventBus.on('setError', this.setError);
    EventBus.on('logoutUser', this.logoutUser);
    EventBus.on('loginClicked', this.loginClicked);
    EventBus.on('setOnlineError', this.setOnlineError);
  },
  beforeUnmount() {
    EventBus.off('setError', this.setError);
    EventBus.off('logoutUser', this.logoutUser);
    EventBus.off('loginClicked', this.loginClicked);
    EventBus.off('setOnlineError', this.setOnlineError);
  },
};
</script>
