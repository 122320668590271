import * as THREE from "three";

export function convertThreeColorToHTML(threeColor) {
    const r = Math.round(threeColor.r * 255);
    const g = Math.round(threeColor.g * 255);
    const b = Math.round(threeColor.b * 255);
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

export function convertNumberColorToHTML(numberColor) {
    const hexColor = numberColor.toString().padStart(6, '0');
    return `#${hexColor.toUpperCase()}`;
}

export function convertHTMLColorToThreeColor(htmlColor) {
    const parsedColor = parseInt(htmlColor.slice(1), 16); // Remove the '#' and convert to decimal
    const r = (parsedColor >> 16) & 255;
    const g = (parsedColor >> 8) & 255;
    const b = parsedColor & 255;
    return new THREE.Color(r / 255, g / 255, b / 255);
}

