import * as THREE from "three";
import {createGizIcon} from "@/render_utils/three_tools/gizmos_tools/giz_icon";
import {DynamicLine} from "@/render_utils/three_tools/creation_tools/DynamicLine";

export function createSunGiz(sun, scene) {
    const target = sun.target;
    const isTarget = !!(target.parent);
    const color = isTarget ? 0x3EBAF4: undefined;
    const icon = createGizIcon(true, true, false, color);

    if(isTarget) {
        const line = new DynamicLine(sun, icon);
        sun.add(icon);
        sun.remove(target);
        sun.target = icon;
        scene.add(sun);
        scene.add(line);
        sun.position.set(0,0,0);
        icon.depends = [line, target];
    }
    else {
        const mid = new THREE.Group();
        mid.name = "REAL_EXTRA";
        const line = new DynamicLine(mid, icon);
        icon.depends = [line, mid];
        icon.add(sun);
        scene.add(mid);
        scene.add(icon);
        scene.add(line);
    }

    icon.position.x += 5;
    icon.position.y += 5;
    icon.position.z += 5;
    icon.userData.canLight = true;
    icon.userData.type = "REAL_SUN_LIGHT";

    return icon;
}
