<template>
  <OptionForm submitName="Export" @submit="exportScene" @close="$emit('closeMenus')">
    <DropForm  label="Export As" :options="['gltf']" @onChange="setType" />
    <div v-if="ext==='gltf'">
      <CheckboxForm label="GLB" :def="options.binary" @onChange="setBinary" />
      <CheckboxForm label="Embed Images" :def="options.embedImages" @onChange="setImages" />
      <CheckboxForm label="Export Matrices" :def="options.trs" @onChange="setMatrices" />
      <CheckboxForm label="Only Visible" :def="options.onlyVisible" @onChange="setVisible" />
    </div>
  </OptionForm>
</template>
<script>
import EventBus from "@/utils/EventBus";
import Scene from "@/render_utils/three_tools/Scene";
import DropForm from "@/components/form/option/DropForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";

export default {
  name: "ExportPage",
  emits: ['closeMenus'],
  components: {CheckboxForm, DropForm, OptionForm},
  props: {
    scene: {type: Scene, required: true},
  },
  data() {
    return {
      options: {
        trs: true,
        binary: true,
        onlyVisible: true,
        embedImages: true,
      },
      ext: "gltf",
    }
  },
  methods: {
    async exportScene(){
      let ext = this.ext;
      let options = this.options;
      switch (ext) {
        case "gltf":
          if(this.options.binary) ext = "glb";
          break;
      }
      this.$emit('closeMenus');
      EventBus.emit("exportScene", {ext: ext, options: options});
    },
    setMatrices(value){
      this.setOptions('trs', value);
    },
    setBinary(value){
      this.setOptions('binary', value);
    },
    setImages(value){
      this.setOptions('embedImages', value);
    },
    setVisible(value){
      this.setOptions('onlyVisible', value);
    },
    setOptions(key, value){
      this.options[key] = value;
    },
    setType(value){
      this.ext = String(value).toLowerCase();
      switch (this.ext) {
        case "gltf":
          this.options = {
            trs: true,
            binary: true,
            onlyVisible: true,
            embedImages: true,
          };
          break;
      }
    },
  }
}
</script>
