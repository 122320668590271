<template>
  <div>
    <label for="color" class="block mb-2 text-sm font-bold text-gray-300 dark:text-white">{{label}}:</label>
    <input type="color" v-model="onChange" :required="require" id="color" class="bg-gray-700 border w-full border-gray-600 focus:border-blue-500" >
  </div>
</template>
<script>
export default {
  name: "ColorForm",
  emits: ['onChange'],
  props: {
    label: {type: String, required: true},
    require: {type: Boolean, required: false, default: ()=>{return false}},
    type: {type: String, required: false, default: () => {return 'text'}},
    def: {type: String, required: false, default: ()=>{return '#FFFFFF'}},
  },
  data () {
    return{
      onChange: this.def
    }},
  watch: {
    onChange(value){
      this.$emit('onChange', value);
    },
  }
}
</script>