import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import EventBus from '@/utils/EventBus';

export default class GltfImporter {
    constructor() {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath( '/draco/' );

        this.loader = new GLTFLoader();
        this.loader.setDRACOLoader(dracoLoader);
    }

    import(file) {
        return new Promise((resolve, reject) => {
            this.loader.load(URL.createObjectURL(file),
                (gltf) => {
                    EventBus.emit('modelLoaded', {ext: "gltf", model: gltf});
                    resolve(gltf.scene);
                },
                undefined,
                (error) => {
                    EventBus.emit('setError', error);
                    reject(error);
                }
            );
        });
    }
}
