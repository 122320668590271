<template>
  <div class="mb-4">
    <label class="block text-gray-300 text-sm font-bold mb-2">
      {{ label }}:
    </label>
    <div class="relative">
      <select v-model="onChange" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
        <option v-for="(option, index) in options" :key="index" :value="option">{{ option }}</option>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <img :src="downArrow" class="fill-current h-4 w-4" alt="arrow" >
      </div>
    </div>
  </div>
</template>
<script>
import downArrow from "@/assets/img/d-arrow.svg";
export default {
  name: "DropForm",
  emits: ['onChange'],
  props: {
    label: {type: String, required: true},
    options: {type: Array, required: true},
  },
  data () {
    return {
      downArrow: downArrow,
      onChange: this.options[0],
  }},
  methods: {
  },
  watch: {
    onChange(value){
      this.$emit('onChange', value);
    },
  }
}
</script>
