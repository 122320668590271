import {MathUtils} from "three";

export function isRealLight(name) {
    if(typeof name !== "string") return false;
    return name.startsWith("REAL_") && name.endsWith("_LIGHT")
}

export function DegToRad(value, round = 6) {
    const radians = MathUtils.degToRad(value);
    return Number(radians.toFixed(round));
}

export function RadToDeg(value, round = 6) {
    const radians = MathUtils.radToDeg(value);
    return Number(radians.toFixed(round));
}
