<template>
  <div class="bg-sc-dark2 rounded-lg info-panel fixed top-1/2 right-0 transform -translate-y-1/2 p-2">
    <div v-if="transObj" class="property">
      <div v-for="(propertyType, index) in ['position', 'rotation', 'scale']" :key="index">
        <div v-if="transform[propertyType]" class="property-group">
          <label class="block text-sm font-medium text-gray-300 capitalize">{{ propertyType }}</label>
          <div v-for="axis in ['x', 'y', 'z']" :key="propertyType + axis" class="flex items-center mt-1 mb-2">
            <label class="text-gray-300 uppercase">{{ axis }}</label>
            <input type="number" :value="getValue(transObj, propertyType, axis)"
                   @change="updatePickObject(propertyType, axis, $event.target.value)"
                   class="ml-2 flex-1 appearance-none bg-sc-dark2-l text-white border border-gray-500 py-1 px-2 rounded-md focus:outline-none focus:border-blue-500 text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {MathUtils} from "three";

export default {
  name: "TransformGui",
  props: {
    transObj: {type: Object, required: true},
    transform: {type: Object, required: true},
  },
  methods: {
    getValue(obj, property, axis) {
      const value = obj[property][axis];
      if(property !== 'rotation') return value;
      return MathUtils.radToDeg(parseFloat(value));
    },
    updatePickObject(property, axis, value) {
      const obj = this.transObj;
      if(!obj) return;
      if(property === 'rotation') obj[property][axis] = MathUtils.degToRad(parseFloat(value));
      else obj[property][axis] = parseFloat(value);
    },
  }
}
</script>
