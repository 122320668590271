import * as THREE from "three";
import {NameParentGiz} from "@/render_utils/three_tools/gizmos_tools/giz_tools";

export function createGizIcon(ignoreScale = false,
                              ignoreRotate = false,
                              ignorePos= false,
                              color = 0xffff00
                              ) {
    const spRadius = 0.05;
    const sphereGeometry = new THREE.SphereGeometry(spRadius, 32, 32);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: color });
    const icon = new THREE.Mesh(sphereGeometry, sphereMaterial);
    NameParentGiz(icon);
    icon.userData.ignorePos = ignorePos;
    icon.userData.ignoreScale = ignoreScale;
    icon.userData.ignoreRotate = ignoreRotate;
    return icon;
}
