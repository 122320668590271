import * as THREE from "three";
import * as REAL from "real_api";
import EventBus from "@/utils/EventBus";
import {saveFileAs} from "@/render_utils/three_tools/export_tools/save_utils";
import R3DExporter from "@/render_utils/three_tools/export_tools/r3d_exporter";
import GltfExporter from "@/render_utils/three_tools/export_tools/gltf_exporter";

export default class RealExporter {
    constructor(scene, onProgress, showProgress, onLoading) {
        this.busy = false;
        this.scene = scene;
        this.warnings = [];
        this.exporter = {
            r3d: R3DExporter,
            glb: GltfExporter,
            gltf: GltfExporter,
        };
        this.sceneCache = {};
        this.onLoading = onLoading;
        this.onProgress = onProgress;
        this.showProgress = showProgress;
        this.sceneItems = this.__initScene();
    }
    async export(ext, options = {}, isRender=false){
        if(this.busy) return EventBus.emit("setError", "Already busy for previous task!");
        if(isRender && !this.scene.modelData.modelCamera)
            return EventBus.emit("setError", "Please add a default camera to render!");
        this.busy = true;
        if(ext === "r3d") this.showProgress(true);
        else this.onLoading(true);
        this.__clear();
        const threeScene = this.scene;
        const scene = threeScene.scene;
        let expScene = undefined;
        if(!scene.isScene) return EventBus.emit("setError", scene.error);
        threeScene.transform.deselectPicked();
        threeScene.setSceneRender(false);
        threeScene.setControlRender(false);
        try {
            const reqList = [];
            const children = scene.children;
            for (const child of children) {
                if(child.isTransformControls || child.isAmbientLight) continue;
                if(["REAL_GRID", "REAL_LINE_GIZ", "REAL_EXTRA"].includes(child.name)) continue;
                reqList.push(child);
            }
            this.__storeCache(children);
            let isDone = this.__prepareExport(ext, scene, reqList, isRender);
            if(!isDone) return this.__finish();
            expScene = await this.__export_to(scene, ext, options);
            if(!expScene) return this.__finish();
            if(!isRender) await saveFileAs(expScene, ext);
        }
        catch (e) {
            EventBus.emit("setError", e)
        }
        this.__finish();
        return expScene;
    }
    expError(errors) {
        let errorMsg = "";
        for (const error of errors) errorMsg += `${error}\n`;
        EventBus.emit("setError", errorMsg);
    }
    __finish() {
        for (const light of this.sceneItems.areaLights) {
            light.position.set(0, 0, 0);
            light.rotation.set(0, 0, 0);
        }
        const threeScene = this.scene;
        this.__resetScene();
        this.__restoreCache();
        this.sceneCache = {};
        threeScene.setSceneRender(true);
        threeScene.setControlRender(true);
        this.busy = false;
        this.onLoading(false);
        this.showProgress(false);
    }
    async __export_to(scene, ext, options){
        const exporter = this.exporter[ext];
        if(!exporter) return EventBus.emit("setError", "File type not supported!");
        const expClass = new exporter();
        if(ext === "r3d") return await expClass.export(scene, this.onProgress, this.expError);
        return await expClass.export(scene, options);
    }
    __storeCache(children) {
        const cache = this.sceneCache;
        for (const child of children){
            const id = this.__cacheSize();
            cache[id] = {child: child, parent: child.parent}
        }
    }
    __resetScene() {
        const scene = this.scene.scene;
        for (const child of scene.children) {
            child.parent.remove(child);
        }
    }
    __restoreCache() {
        const cache = this.sceneCache;
        const entries = Object.keys(cache);
        for (const entry of entries) {
            const item = cache[entry];
            const child = item.child;
            const parent = item.parent;
            if (parent) parent.attach(child);
        }
    }
    __cacheSize() {
        const cache = this.sceneCache;
        const entries = Object.keys(cache);
        return entries.length;
    }
    __initScene(){
        return {
            extras:[],
            models:[],
            cameras:[],
            sunLights:[],
            spotLights:[],
            areaLights:[],
            pointLights:[],
        }
    }
    __prepareExport(ext, scene, children) {
        try {
            const sceneItems = this.sceneItems;
            const sceneChildren = [...scene.children];
            for (const child of sceneChildren) {
                if(child.parent) child.parent.remove(child);
            }
            for (const child of children) {
                const type = child.userData.type;
                if(type === "REAL_AREA_LIGHT") sceneItems.areaLights.push(child);
                else if(type === "REAL_CAM_GIZ") this.__addToList('isCamera', child, sceneItems.cameras);
                else if(type === "REAL_SPOT_LIGHT") {
                    const subChild = this.__getChild('isSpotLight', child);
                    if(!subChild) continue;
                    sceneItems.spotLights.push(subChild);
                    sceneItems.extras.push(child);
                }
                else if(type === "REAL_POINT_LIGHT") {
                    const subChild = this.__getChild('isPointLight', child);
                    if(!subChild) continue;
                    sceneItems.pointLights.push(subChild);
                    sceneItems.extras.push(child);
                }
                else if(type === "REAL_SUN_LIGHT") {
                    const subChild = this.__getChild('isDirectionalLight', child);
                    if(!subChild) continue;
                    sceneItems.sunLights.push(subChild);
                    sceneItems.extras.push(child);
                    // let isExtra = false;
                    // for (const subChild of child.children) {
                    //     if(!subChild.isDirectionalLight) continue;
                    //     sceneItems.sunLights.push(subChild);
                    //     isExtra = true;
                    //     break;
                    // }
                    // if(isExtra) sceneItems.extras.push(child);
                }
                else if(child.name === "REAL_Empty_GIZ") {
                    if (child.children.length) sceneItems.models.push(child.children[0]);
                }
            }

            const keys = Object.keys(sceneItems);
            for (const key of keys) {
                const reqChildren = sceneItems[key];
                if(!reqChildren || !reqChildren.length) continue;
                this.__storeCache(reqChildren);
            }
            this.__addToScene('models', scene);
            this.__addToScene('cameras', scene);
            if(ext === "r3d") {
                for (const light of sceneItems.areaLights) {
                    scene.attach(light);
                    const child = light.children[0];
                    const temp = new THREE.Group();
                    light.add(temp);
                    temp.position.copy(child.position);
                    temp.rotation.copy(child.rotation);
                    scene.attach(temp);
                    light.position.copy(temp.position);
                    light.rotation.copy(temp.rotation);
                    scene.remove(temp);
                    scene.remove(light);
                }
                this.__addToScene('sunLights', scene);
                this.__addToScene('areaLights', scene);
                this.__addToScene('spotLights', scene);
                this.__addToScene('pointLights', scene);
            }
            return true;
        }
        catch (e) {
            EventBus.emit("setError", e)
        }
    }
    __getChild(key, child) {
        for (const subChild of child.children) {
            if(subChild[key]) return subChild;
        }
    }
    __prepareLights(scene) {
        try {
            const areaLights = this.sceneItems.areaLights;
            const sunLights = this.sceneItems.sunLights;
            for (const light of areaLights) {
                const child = light.name === "REAL_AREA_LIGHT" ? light.children[0] : light;
                const parsedLight = new REAL.AreaLight(child.scale.x, child.scale.y, light.color);
                light.add(parsedLight);
                parsedLight.scale.copy(child.scale);
                parsedLight.position.copy(child.position);
                parsedLight.rotation.copy(child.rotation);
                scene.attach(parsedLight);
            }
            for (const light of sunLights) {
                const parent = light.parent;
                const cl = light.clone();
                const parse = light.parse();
                console.log(parse)
                if(cl.parent) cl.parent.remove(cl);
                cl.scale.copy(parent.scale);
                cl.rotation.copy(parent.rotation);
                cl.position.copy(parent.position);
                cl.rotation.x -= Math.PI/4;
                cl.rotation.z += Math.PI/4;
                scene.add(cl);
            }
            // return true;
        }
        catch (e) {
            EventBus.emit("setError", e)
        }
    }
    __addToScene(key, scene, isSelf=false) {
        const items = this.sceneItems[key];
        if(!items || !items.length) return;
        if(isSelf) {scene.attach(items);}
        else {
            for (const item of items) {
                scene.attach(item);
            }
        }
    }
    __clear(){
        this.warnings = [];
        this.sceneCache = {};
        this.sceneItems = this.__initScene();
    }
    __addToList(key, parent, storeList){
        for (const child of parent.children) {
            if(child[key]) storeList.push(child);
        }
    }
}
