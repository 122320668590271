import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import EventBus from '@/utils/EventBus';

export default class FbxImporter {
    constructor() {
        this.loader = new FBXLoader();
    }

    import(file) {
        return new Promise((resolve, reject) => {
            this.loader.load(URL.createObjectURL(file),
                (fbx) => {
                    EventBus.emit('modelLoaded', {ext: "fbx", model: fbx});
                    resolve(fbx);
                },
                undefined,
                (error) => {
                    EventBus.emit('setError', error);
                    reject(error);
                }
            );
        });
    }
}
