<template>
  <OptionForm @submit="addLight" @close="$emit('closeMenus')">
    <ColorForm label="Color" :def="color" @onChange="setColor" />
    <InputForm label="Intensity" type="number" :def="intensity" @onChange="setIntensity" />
<!--    <InputForm label="Decay" type="number" :def="decay" @onChange="setDecay" />-->
    <InputForm label="Distance" type="number" :def="distance" @onChange="setDistance" />
    <CheckboxForm label="Cast Shadow" :def="castShadow" @onChange="setCastShadow" />
  </OptionForm>
</template>
<script>
import * as REAL from "real_api"
import ColorForm from "@/components/form/option/ColorForm.vue";
import InputForm from "@/components/form/option/InputForm.vue";
import OptionForm from "@/components/form/option/OptionForm.vue";
import CheckboxForm from "@/components/form/option/CheckboxForm.vue";
import {convertHTMLColorToThreeColor} from "@/render_utils/three_tools/creation_tools/color_tools";


export default {
  name: "AddPointLight",
  components: {OptionForm, InputForm, ColorForm, CheckboxForm},
  data() {
    return {
      color: '#FFFFFF',
      decay: 2,
      intensity: 1,
      distance: 20.0,
      castShadow: true,
    }
  },
  methods: {
    addLight() {
      this.$emit("closeMenus");
      const color  = convertHTMLColorToThreeColor(this.color);
      const light = new REAL.PointLight({
        color: color,
        decay: this.decay,
        distance: this.distance,
        intensity: this.intensity,
        castShadow: this.castShadow,
      });
      this.$emit("addSceneItem", light, "point_light");
    },
    setColor(value){this.color = value},
    setDecay(value){this.decay = value},
    setDistance(value){this.distance = value},
    setCastShadow(value){this.castShadow = value},
    setIntensity(value){this.intensity = value},
  }
}
</script>
