import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import EventBus from '@/utils/EventBus';
import {NameExtra} from "@/render_utils/three_tools/gizmos_tools/giz_tools";

class Transform {
    constructor(orbitControls, renderer, camera, scene) {
        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;
        this.orbitControls = orbitControls;
        this.clickList = [];
        this.start = 0;
        this.raycastLayer = new THREE.Layers();
        this.raycastLayer.set(1);

        this.actionStack = null;
        this.control = new TransformControls(this.camera, this.renderer.domElement);
        NameExtra(this.control);
        this.control.setSize(0.5);
        this.control.setSpace('local');
        this.isActiveTrans = false;
        this.scene.add(this.control);

        this.renderer.domElement.addEventListener('mousedown', this.mouseDown.bind(this), false);
        this.renderer.domElement.addEventListener('mouseup', this.mouseUp.bind(this), false);
        this.control.addEventListener('dragging-changed', this.dragEvent.bind(this));
        this.control.addEventListener('objectChange', this.dragging.bind(this));

        window.addEventListener('keydown', this.handleKeyDown.bind(this), false);

    }
    mouseDown() {
        this.start = new Date();
        this.isActiveTrans = false;
    }
    mouseUp(e) {
        this.handleClick(e)
    }
    dragEvent(event) {
        const enabled = event.value;
        if(enabled) this.dragStart();
        else this.dragEnd();
    }
    dragStart() {
        this.orbitControls.enabled = false;
        this.isActiveTrans = true;
        this.pushAction(this.control.object.matrix.clone());
    }
    dragging() {
        this.isActiveTrans = true;
        const obj = this.getSelected();
        if(!obj) return;
        EventBus.emit('setTransformObj', {
            position: obj.position,
            rotation: obj.rotation,
            scale: obj.scale
        });
    }
    dragEnd() {
        this.orbitControls.enabled = true;
    }
    handleClick(e) {
        e.preventDefault();
        if (this.isActiveTrans || this.mouseTime() > 0.25) return;

        const rect = this.renderer.domElement.getBoundingClientRect();
        const x = (e.clientX - rect.left) / rect.width;
        const y = (e.clientY - rect.top) / rect.height;

        const mouse = new THREE.Vector2();
        mouse.x = (x * 2) - 1;
        mouse.y = - (y * 2) + 1;

        const rayCaster = new THREE.Raycaster();
        rayCaster.layers = this.raycastLayer;
        rayCaster.setFromCamera(mouse, this.camera);

        const intersects = rayCaster.intersectObjects(this.clickList);

        if (intersects.length > 0)this.setAsPicked(intersects[0].object);
        else this.deselectPicked();
    }
    setAsPicked(obj) {
        if(!obj || this.control.object === obj) return;
        this.clearAction();
        this.control.attach(obj);
        const data = obj.userData;
        const canLight = data.canLight;
        const canClone = data.canClone;
        const canPos = !data.ignorePos;
        const canScale = !data.ignoreScale;
        const canRotate = !data.ignoreRotate;
        EventBus.emit('setTransformUI', {
            enable: true,
            position: canPos,
            light: canLight,
            clone: canClone,
            scale: canScale,
            rotation: canRotate,
        });
        EventBus.emit('setTransformObj', {
            position: obj.position,
            rotation: obj.rotation,
            scale: obj.scale
        });
    }
    deselectPicked() {
        this.clearAction();
        this.control.detach();
        EventBus.emit('setTransformUI', {enable: false});
    }
    getSelected() {
        return this.control.object;
    }
    clearAction() {
        this.actionStack = null;
    }
    mouseTime() {
        return (new Date() - this.start) / 1000;//((3600 * 3600) * (1000 * 1000));
    }
    setTransformMode(mode) {
        this.control.setMode(mode);
    }
    addClickableObject(object) {
        if(!object) return;
        if(this.clickList.includes(object)) return;
        object.layers.set(1);
        this.clickList.push(object);
    }
    pushAction(matrix) {
        this.actionStack = matrix;
    }
    handleKeyDown(e) {
        if (e.ctrlKey && e.key === 'z') this.undo();
        else if(e.key === 'Delete') this.delete();
    }
    delete() {
        const picked = this.getSelected();
        if(!picked) return;
        const parent = picked.parent;
        if(!parent) return;

        if(picked.depends && Array.isArray(picked.depends)){
            const depends = picked.depends;
            for (const depend of depends) {
                if(depend.parent) depend.parent.remove(depend);
            }
        }

        if(["REAL_SUN_LIGHT", "REAL_AREA_LIGHT"].includes(picked.parent.name)) {
            parent.parent.remove(parent);
            this.deselectPicked();
            this.removeClickableObject(picked);
            return parent.remove(picked);
        }

        if(parent.id === this.scene.id) {
            this.deselectPicked();
            this.removeClickableObject(picked);
            return this.scene.remove(picked);
        }


        // console.log(picked);
    }
    undo() {
        if (!this.actionStack) return;
        const obj = this.control.object;
        obj.matrix.copy(this.actionStack);
        obj.matrix.decompose(obj.position, obj.quaternion, obj.scale);
    }
    removeClickableObject(object) {
        if(!object) return;
        const index = this.clickList.indexOf(object);
        if (index > -1) {
            this.clickList.splice(index, 1);
        }
    }
}

export default Transform;
