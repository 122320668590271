import * as THREE from "three";
import EventBus from '@/utils/EventBus';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

export default class StlImporter {
    constructor() {
        this.loader = new STLLoader();
    }

    import(file) {
        return new Promise((resolve, reject) => {
            this.loader.load(URL.createObjectURL(file),
                (geometry) => {
                    const material = new THREE.MeshStandardMaterial();
                    const mesh = new THREE.Mesh(geometry, material);
                    EventBus.emit('modelLoaded', {ext: "stl", model: mesh});
                    resolve(mesh);
                },
                undefined,
                (error) => {
                    EventBus.emit('setError', error);
                    reject(error);
                }
            );
        });
    }
}
