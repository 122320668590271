import * as THREE from "three";
import {NameChildGiz} from "@/render_utils/three_tools/gizmos_tools/giz_tools";

export function createAreaGiz(light) {
    const color = new THREE.Color(0xf5b856);
    const material = new THREE.LineBasicMaterial({ color: color });
    const points = [
        new THREE.Vector3(-1/2, -1/2, 0),
        new THREE.Vector3(1/2, -1/2, 0),
        new THREE.Vector3(1/2, 1/2, 0),
        new THREE.Vector3(-1/2, 1/2, 0),
        new THREE.Vector3(-1/2, -1/2, 0)
    ];
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const outline = new THREE.Line(geometry, material);
    light.add(outline);
    NameChildGiz(outline);
    light.userData.canLight = true;
    light.userData.gizID = outline.id;
    light.userData.type = "REAL_AREA_LIGHT";
    light.parent.userData.type = "REAL_AREA_LIGHT";
}
